export const config = {
  collectionName: 'Heepow',
  domain: 'https://heepow-me.vercel.app',
  discord: 'https://discord.com/invite/6kKjaAHpxg',
  twitter: 'https://twitter.com/MHeepow',
  buyNow: 'https://www.asalytic.app/collection/heepow?tab=market&traitTypes=&traitValues=&filterTypes=&filterValues=',
  buyNowBrat: 'https://www.asalytic.app/collection/brats-collection?tab=market&traitTypes=&traitValues=&filterTypes=&filterValues=',
  viewCollection: 'https://www.asalytic.app/collection/heepow?tab=market&traitTypes=&traitValues=&filterTypes=&filterValues=',
  viewCollectionBrats: 'https://www.asalytic.app/collection/brats-collection?tab=market&traitTypes=&traitValues=&filterTypes=&filterValues=',
  email: 'myalgoheepow@gmail.com',
  linktree: 'https://linktr.ee/Heepow',
  faqs: [
    {
      question: 'How many “OG” Heepows (V1.0) will there be?',
      answer: '254 Unique 1/1 hand drawn nfts',
    },
    {
      question: 'How many V2.0 Heepows will there be?',
      answer: '2,400 Generative NFTs and 180 unique 1/1',
    },
    {
      question: 'Will there be V3.0 Heepows?',
      answer:
        'Yes, by early Q2 2024 we will launch Heepow V3.0. V3 will be a generated collection of 2,400 NFTs. There will be 254 hand curated NFTs with special element traits for Parasite holders.',
    },
    {
      question: 'What Discord roles can I earn by holding Heepow NFTs?',
      answer:
        'Holder 1-4 v1 Governor 5-9 v1 Emperor 10+ v1 Playful Brat 1-4 v2 Wild Brat 5-9 v2 Rascal Brat 10-24 v2 Bully Brat 25-49 v2 Tyrant Brat 50-99 v2 Divine 100+ v2',
    },
    {
      question: 'Why Heepow?',
      answer:
        'At the Heepow project, we aim to make your NFT experience a good one. With a supportive community, games, daily interactions with our Graze bot, missions, staking and weekly raffles… we offer something for everyone in the NFT space.',
    },
  ],
  features: [
    {
      name: 'Be part of the world’s loudest NFT community',
      description:
        "When you join the Heepow Herd by purchasing an NFT or just joining our Discord, you're instantly part of our community. A global community that empowers and supports each other on this exciting journey into Web 3.0. We're focused on building authentic connections as we delve into the metaverse together.",
    },
    {
      name: 'Access Heepow Rewards',
      description:
        'As a Heepow NFT holder, you automatically gain access to our exclusive Heepow rewards program which rewards you for participating in the community. Holders receive daily $GRASS rewards for each NFT they hold. $GRASS/$ALGO LP providers on Tinyman v2.0 receive daily $GRASS rewards. Weekly staking rewards for v1 and 1/1 Brat holders (Soft staking, you hold your NFTs). Weekly Mission rewards for Brat holders who send their NFTs on missions (Soft staking, you hold your NFTs). Our program will continuously evolve as we look at new ways to reward our members with additional benefits.',
    },
    {
      name: 'Own the full commercial rights',
      description:
        'When you purchase a Heepow NFT, the art is totally and completely yours. Whether you want to print and sell merchandise, use it in advertising, or want it to be the star of its own show!',
    },
    {
      name: 'Display your art in high resolution',
      description:
        'Heepow NFTs are available in high resolution with our OG v1 collection 4,000x4,000px JPGs, v2 collection 1,600x1,600px JPGs and v3 collection will be 4,000x4,000px JPGs. This allows you to print a high-quality physical copy of your NFT, display it in high res on a screen, and use it for large-scale print or display.',
    },
    {
      name: 'Have a voice in the project',
      description: 'Heepow ownership gives you a voice in the development and success of the project through formalised voting systems.',
    },
    {
      name: 'Receive Airdrops',
      description: `As a Heepow NFT holder, you'll be eligible for future airdrops of coins and other prizes.`,
    },
    {
      name: 'Staking & Missions',
      description: `When you hold a Heepow NFT, you open the door to passive rewards of our community token $GRASS. Participating in our staking platform and Missions platform allow holders to unlock more rewards including $GRASS and $ALGO!`,
    },
  ],
  teamLead: {
    name: 'oaksandhoes',
    role: 'HEEPOW Project Lead',
    twitterUrl: 'https://twitter.com/ccole7604',
    imageUrl: '/oaks.png',
  },
  team: [
    {
      name: 'Stiggy Dawgs',
      role: 'Team Member',
      twitterUrl: 'https://twitter.com/StigDawgs',
      imageUrl: '/team/stig.webp',
    },
    {
      name: 'Slimdogg6',
      role: 'Team Member',
      twitterUrl: 'https://twitter.com/Slimdogg6',
      imageUrl: '/team/slim.webp',
    },
    {
      name: 'oshiroroshi',
      role: 'Team Member',
      twitterUrl: 'https://twitter.com/AlgoBBot',
      imageUrl: '/team/oshi.webp',
    },
    {
      name: 'farawayjunk',
      role: 'Team Member',
      twitterUrl: 'https://twitter.com/FarawayJunk',
      imageUrl: '/team/faraway.webp',
    },
    {
      name: 'Thors Hammer',
      role: 'Team Member',
      twitterUrl: '',
      imageUrl: '/team/thor.webp',
    },
    {
      name: 'Elon',
      role: 'Artist',
      imageUrl: '/team/elon.webp',
    },
  ],
  stakingDetails: [
    {
      heading: 'Heepows stay in your wallet',
      description: `You don't have to send your Heepows to an escrow wallet. Your Heepows stay in your wallet while staked.`,
    },
    {
      heading: 'Eligibility',
      description:
        'Heepows are only eligible to stake in the current period if they have not been involved in a transaction in the current staking period.',
    },
    {
      heading: 'Wallet checks',
      description:
        'Every so often, all staked Heepows are checked and then removed from staking if they have been involved in a transaction during the current staking period.',
    },
    {
      heading: 'Staking Rewards',
      description:
        'If you keep your Heepows staked and in your wallet, on Sundays you will receive a reward based on how many Heepows you have staked.',
    },
  ],
  exchangeDetails: [
    {
      heading: 'New Exchanges once a week',
      description: 'Every Sunday, 1-2 Heepows will be added here and be available for the Heepow Exchange Shuffle.',
    },
    {
      heading: 'Entry Requirements',
      description:
        'You must own at least 1 Heepow to enter the Exchange Shuffle. If you win the Exchange Shuffle, you will be required to pay $grass for the exchange.',
    },
    {
      heading: 'Exchange Shuffle',
      description: 'You will have 48 hours to enter. Entering the Exchange Shuffle gives you a chance to exchange your old Heepow for a new Heepow.',
    },
    {
      heading: 'Winning the Exchange Shuffle',
      description:
        'As each Exchange Shuffle ends, one winner will be randomly selected. The winner will exchange $grass and their old Heepow for the newly designed Heepow.',
    },
    {
      heading: 'Who won?',
      description: 'The winning address will be posted beside each Exchange Shuffle as well as announced in the `🔄-exchanges` channel in Discord.',
    },
  ],
  heepowWallet: '7WE3HVHVLNTBWHAJX7RZQPY5YW3NS4PVUF3DFP6EALRDFEYE7A4ME2EBDI',
  bratsWallet: 'BRATZLXKQZFNTONK7UNNTXVONWTLS6WUZFMFM4ICGAIK5H6LBLYTHYRFL4',
  deviantsWallet: 'PIK6BJQCINKNI7EDIOLI7CV4SQHQEQLYA7P5USQ4TJKPVC4JUZQI467E44',
  grassWallet: '7OI3Y3EKJH34XDHIPJSCYJTU5GKY4SMA7GUAQ5EIRCVWYUZ7PYQHJVWIZI',
  grassTokenId: 765171236,
  // grassWallet: 'O3FEIZK24PBBULXD4ZVONFLMQG6KFGQM6WCFY6HATOFVYTR74A3WG636FE',
  // grassTokenId: 847993215,

  fragments: {
    blue: 1080445087,
    red: 1080445568,
    gold: 1080444466,
  },

  chestIds: {
    bronze: 1080447247,
    silver: 1080447876,
    gold: 1080448167,
    diamond: 1080449075,
    platinum: 1080448633,
    blackCat: 1080449747,
    whiteCat: 1080449441,
  },

  rewards: {
    bronze: 30,
    silver: 30,
    gold: 25,
    platinum: 10,
    diamond: 5,
  },
};
